

































































































































































































































































































































































































































































@media screen and (max-width:767px){
  .authentication-form{
    .input-group-text{
      display: none;
    }
    .invalid-feedback {
      margin-left: 0 !important;
    }
    .form-control-label.text-white{
      color: #4b4b5a !important;
      display: block !important;
    }
  }
  .card-form{
    label[for="card-number-element"]{
      display: none;
    }
  }
}
